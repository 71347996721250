import * as THREE from 'three/src/Three'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Canvas, useRender, extend } from 'react-three-fiber'
import * as meshline from 'three.meshline'

import './styles.css'

import Controls from './components/controls'
import Model from './components/model'

extend(meshline)

const numLines = 100
const lines = new Array(numLines).fill()
const colors = ['#FF8C00', '#348F50', '#56B4D3', '#FF0080', '#3F5EFB', '#FFD200']

function Fatline() {
  const material = useRef()
  const [color] = useState(() => colors[parseInt(colors.length * Math.random())])
  const [ratio] = useState(() => 0.5 + 0.5 * Math.random())
  const [width] = useState(() => Math.max(0.1, 0.3 * Math.random()))
  // Calculate wiggly curve
  const [curve] = useState(() => {
    let pos = new THREE.Vector3(200 - 400 * Math.random(), -200, 200 - 400 * Math.random())
    return new Array(30)
      .fill()
      .map(() => pos.add(new THREE.Vector3(2 - Math.random() * 4, 16 - Math.random() * 8, 5 - Math.random() * 10)).clone())
  })
  // Hook into the render loop and decrease the materials dash-offset
  useRender(() => (material.current.uniforms.dashOffset.value -= 0.0005))
  return (
    <mesh>
      {/** MeshLine and CMRCurve are a OOP factories, not scene objects, hence all the imperative code in here :-( */}
      <meshLine onUpdate={self => (self.parent.geometry = self.geometry)}>
        <geometry onUpdate={self => self.parent.setGeometry(self)}>
          <catmullRomCurve3 args={[curve]} onUpdate={self => (self.parent.vertices = self.getPoints(500))} />
        </geometry>
      </meshLine>
      {/** MeshLineMaterial on the other hand is a regular material, so we can just attach it */}
      <meshLineMaterial
        attach="material"
        ref={material}
        transparent
        // depthTest={false}
        lineWidth={width}
        color={color}
        dashArray={0.1}
        dashRatio={ratio}
      />
    </mesh>
  )
}

function Lines() {
  let group = useRef()
  let theta = 0
  // Hook into the render loop and rotate the scene a bit
  useRender(() => group.current.rotation.set(0, 5 * Math.sin(THREE.Math.degToRad((theta += 0.0015))), 0))
  return (
    <group ref={group}>
      {lines.map((_, index) => (
        <Fatline key={index} />
      ))}
    </group>
  )
}

function Stars() {
  let group = useRef()
  let theta = 0
  useRender(() => {
    // Some things maybe shouldn't be declarative, we're in the render-loop here with full access to the instance
    const r = 5 * Math.sin(THREE.Math.degToRad((theta += 0.0015)))
    const s = Math.cos(THREE.Math.degToRad(theta * 2))
    group.current.rotation.set(r, r, r)
    group.current.scale.set(s, s, s)
  })
  const [geo, mat, vertices, coords] = useMemo(() => {
    const geo = new THREE.SphereBufferGeometry(1, 10, 10)
    const mat = new THREE.MeshBasicMaterial({ color: new THREE.Color('#F6F0C4') })
    const coords = new Array(500).fill().map(i => [Math.random() * 400 - 200, Math.random() * 400 - 200, Math.random() * 400 - 200])
    return [geo, mat, vertices, coords]
  }, [])
  return (
    <group ref={group}>
      {coords.map(([p1, p2, p3], i) => (
        <mesh key={i} geometry={geo} material={mat} position={[p1, p2, p3]} />
      ))}
    </group>
  )
}

export default function App() {
  useEffect(() => {
    document.getElementById('audio').volume = 0.25
  }, [])

  return (
    <>
      <audio id="audio" volume="0.1" autoPlay="autoplay" controls="" tabIndex="0" src="satellite-of-love.mp3"></audio>
      <div className="birthday">Наташа, с днём рождения&nbsp;!</div>
      <div className="emoji">
        <span role="img" aria-label="emoji">
          🥳
        </span>
        &nbsp;&nbsp;&nbsp;
        <span role="img" aria-label="emoji">
          🎉
        </span>
      </div>
      <Canvas
        onClick={() => {
          document.getElementById('audio').play()
        }}>
        <ambientLight intensity={1.5} />
        <pointLight intensity={2} position={[-10, -25, -10]} />
        <spotLight
          castShadow
          intensity={1.25}
          angle={Math.PI / 8}
          position={[25, 25, 15]}
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
        />
        <Lines />
        <Model url="/scene.gltf"></Model>
        <Stars />
        <Controls
          autoRotate
          autoRotateSpeed={0.15}
          enableDamping
          enablePan={false}
          dampingFactor={0.5}
          rotateSpeed={0.3}
          maxPolarAngle={Math.PI / 2.7}
          minPolarAngle={Math.PI / 2.7}
          zoomSpeed={0.5}></Controls>
      </Canvas>
    </>
  )
}
