import React from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

function Model({ url }) {
  const [scene, setScene] = React.useState()

  React.useMemo(
    () =>
      new GLTFLoader().load(url, gltf => {
        gltf.scene.traverse(function(child) {
          if (child.isMesh) {
            child.geometry.center()
          }
        })
        gltf.scene.scale.set(0.009, 0.009, 0.009)
        setScene(gltf.scene)
      }),
    [url]
  )

  return scene ? <primitive object={scene} /> : null
}

export default Model
